import React from "react"
import { useIntl } from "gatsby-plugin-intl"

import siteUtils from "../../utils/context/site-utils"
import ContextCommonPage from "../../components/context"

const SitePage = () => {
  const intl = useIntl()
  const { entity, formFields } = siteUtils.getProps({ intl })

  return (
    <ContextCommonPage
      entity={entity}
      formFields={formFields}
      prop="sites"
      formTitle={intl.formatMessage({ id: "create-new-site" })}
    />
  )
}

export default SitePage
