const getProps = ({ intl }) => ({
  entity: {
    name: "Site",
    id: "site",
    menuId: "context-site-index",
    uri: "/sites",
    translate: "models-sites",
    label: intl.formatMessage({
      id: "models-site",
    }),
  },
  formFields: [
    {
      name: "id",
      type: "text",
      placeholder: intl.formatMessage({
        id: "page-contexts-sites-code-placeholder",
      }),
      rules: [
        {
          required: true,
          message: intl.formatMessage({
            id: "page-contexts-sites-code-missing",
          }),
        },
      ],
    },
    {
      name: "desc",
      type: "text",
      placeholder: intl.formatMessage({ id: "description" }),
      rules: [
        {
          required: true,
          message: intl.formatMessage({
            id: "page-contexts-sites-description-missing",
          }),
        },
      ],
    },
  ],
})

const siteUtils = {
  getProps,
}

export default siteUtils
